import React, {useState} from 'react';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//import './AppStyles.css';

const Comments = ({ comments, onClose, onCommentSubmit }) => {
    const [newComment, setNewComment] = useState('');

    const handleSubmit = (event) => {
      event.preventDefault(); // Prevent the default form submission behavior
  
      if (newComment.trim()) {
          onCommentSubmit(newComment);
          setNewComment(''); // Reset input field after submission
      }
    };
    return (
        <Drawer anchor="right" open variant="persistent" sx={{'& .MuiDrawer-paper': {width: 350, backgroundColor: '#f5f5f5'}}}>
          <Box
            sx={{
              width: 350, // Adjust the width as needed
              padding: 2,
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Comments
            </Typography>
            {comments.length > 0 ? (
              <List>
                {comments.map((commentObj, index) => (
                  <ListItem key={index} sx={{ marginBottom: 2 }}>
                    <Card variant="outlined" sx={{ width: '100%', padding: 2, backgroundColor: '#ffffff' }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {commentObj.email}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body1">
                        {commentObj.comment}
                      </Typography>
                    </Card>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No comments yet.</Typography>
            )}
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 2,
              }}
              onSubmit={handleSubmit}
            >
              <TextField
                variant="outlined"
                multiline
                maxRows={4}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Leave a comment..."
                sx={{ marginBottom: 2 }}
              />
              <Button variant="contained" color="secondary" type="submit" sx={{backgroundColor: '#41b8d4', color: '#ffffff' }}>
                Submit
              </Button>
            </Box>
          </Box>
        </Drawer>
      );
}

export default Comments;
