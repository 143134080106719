import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function CategoryDropdown({ categories, selectedCategory, onCategoryChange }) {
  return (
    <FormControl color='secondary' sx={{ width: '70%', mx: 'auto' }} >
      <InputLabel id="category-dropdown-label">Category</InputLabel>
      <Select
        labelId="category-dropdown-label"
        value={selectedCategory}
        onChange={onCategoryChange}
        label="Category"
        fullWidth
      >
        <MenuItem value="All Categories">
          All Categories
        </MenuItem>
        {categories.map((category, index) => (
          <MenuItem key={index} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}


export default CategoryDropdown;
