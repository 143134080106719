import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import './AppStyles.css';
import AWS from 'aws-sdk';
import EditablePromptResult from './EditablePromptResult';
import PromptResult from './PromptResult';
import Comments from './Comments';
import { update } from 'lodash';

const SearchResultsContainer = ({ prompts, isAdmin, isAdminView, onVoteCompletion }) => {
    const [activePrompt, setActivePrompt] = useState(null); // holds the active prompt for which comments are shown
    const [currentUserUsername, setCurrentUserUsername] = useState(null);

    useEffect(() => {
      // Fetch the current user's username asynchronously and store it in state
      const fetchUser = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          setCurrentUserUsername(user.username);
        } catch (err) {
          console.error('Error fetching current user', err);
          // Handle error, e.g., by setting username to null or showing an error message
        }
      };
  
      fetchUser();
    }, []);
    const dynamoDb = new AWS.DynamoDB.DocumentClient();
    const handleUpdatePrompt = async (originalTitle, originalCategory, updatedPrompt) => {
        if (!updatedPrompt.title || !updatedPrompt.category || updatedPrompt.title.trim() === "" || updatedPrompt.category.trim() === "") {
            console.error("Invalid originalTitle or originalCategory: exiting function");
            return; // Exit the function early
        }
        const isKeyChanged = originalTitle !== updatedPrompt.title || originalCategory !== updatedPrompt.category;
    
        const originalKey = {
            "Title": originalTitle,
            "Category": originalCategory
        };
    
        // Delete the original item if the key has changed
        if (isKeyChanged) {
            let currentItem;
            try {
                const currentData = await dynamoDb.get({ TableName: "AlphaGrimoires", Key: originalKey }).promise();
                currentItem = currentData.Item;
    
                await dynamoDb.delete({ TableName: "AlphaGrimoires", Key: originalKey }).promise();
            } catch (error) {
                console.error("Error deleting original prompt:", error);
                return; // Exit if deletion fails
            }
    
            // Create a new item with the updated title and/or category
            const newItem = {
                "Title": updatedPrompt.title,
                "Category": updatedPrompt.category,
                "OtherCategories": updatedPrompt.otherCategories, // Add this line
                "Body": updatedPrompt.body,
                "Upvotes": currentItem.Upvotes,
                "Downvotes": currentItem.Downvotes,
                "UserUpvotes": currentItem.UserUpvotes,
                "UserDownvotes": currentItem.UserDownvotes,
                "Comments": currentItem.Comments,
                "Link": updatedPrompt.link || "",
                "DocLink": updatedPrompt.docLink || ""
            };
    
            try {
                await dynamoDb.put({ TableName: "AlphaGrimoires", Item: newItem }).promise();
                onVoteCompletion();
            } catch (error) {
                console.error("Error creating updated prompt:", error);
                return; // Exit if creation fails
            }
        } else {
            // Prepare dynamic update expression and attribute values
            let updateExpression = "set Body = :b, OtherCategories = :oc";
            let expressionAttributeValues = {
                ":b": updatedPrompt.body,
                ":oc": updatedPrompt.otherCategories
            };
    
            // Conditionally add the link to the update if it's present
            if (updatedPrompt.link !== undefined && updatedPrompt.link !== null) {
                updateExpression += ", Link = :l";
                expressionAttributeValues[":l"] = updatedPrompt.link;
            }
            if (updatedPrompt.docLink !== undefined && updatedPrompt.docLink !== null) {
                updateExpression += ", DocLink = :dl";
                expressionAttributeValues[":dl"] = updatedPrompt.docLink;
            }
    
            // Update parameters including the conditional link
            const updateParams = {
                TableName: "AlphaGrimoires",
                Key: originalKey,
                UpdateExpression: updateExpression,
                ExpressionAttributeValues: expressionAttributeValues,
                ReturnValues: "UPDATED_NEW"
            };
    
            try {
                await dynamoDb.update(updateParams).promise();
                onVoteCompletion();
            } catch (error) {
                console.error("Error updating prompt:", error);
            }
        }
    };
    
    
    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
    

    const handleVote = async (title, category, isUpvote) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userId = currentUser.username; // Assuming username is the unique identifier

        const key = {
            "Title": title,
            "Category": category
        };
    
        try {
            const currentData = await dynamoDb.get({ TableName: "AlphaGrimoires", Key: key }).promise();
            const currentItem = currentData.Item;
            // Convert the DynamoDB sets to JavaScript arrays
            const userUpvotesArray = currentItem.UserUpvotes ? currentItem.UserUpvotes.values : [];
            const userDownvotesArray = currentItem.UserDownvotes ? currentItem.UserDownvotes.values : [];
            
            const hasUpvoted = userUpvotesArray.includes(userId);
            const hasDownvoted = userDownvotesArray.includes(userId);              
    
            let updateExpression = "";
            const expressionAttributeValues = {};
    
            if (isUpvote) {
                if (!hasUpvoted) {
                    // Initialize the SET clause for Upvotes
                    updateExpression = "SET Upvotes = Upvotes + :val";
                    expressionAttributeValues[":val"] = 1;
            
                    // Initialize clauses for ADD and DELETE
                    let addClause = "";
                    let deleteClause = "";
            
                    // Logic to handle UserUpvotes set
                    if (!currentItem.UserUpvotes) {
                        addClause = " UserUpvotes = :newUpvoteSet";
                        expressionAttributeValues[":newUpvoteSet"] = dynamoDb.createSet([userId]);
                    } else {
                        addClause = " ADD UserUpvotes :addUpvoteUserId";
                        expressionAttributeValues[":addUpvoteUserId"] = dynamoDb.createSet([userId]);
                    }
            
                    // Remove user from UserDownvotes set and decrement Downvotes
                    if (hasDownvoted) {
                        updateExpression += ", Downvotes = Downvotes - :val";
                        deleteClause = " DELETE UserDownvotes :deleteUpvoteUserId";
                        expressionAttributeValues[":deleteUpvoteUserId"] = dynamoDb.createSet([userId]);
                    }
            
                    // Combine the clauses
                    updateExpression = updateExpression + (addClause ? " " + addClause : "") + (deleteClause ? " " + deleteClause : "");
                } else if (hasUpvoted){
                        // Initialize the SET clause for Upvotes
                    updateExpression = "SET Upvotes = Upvotes - :val";
                    expressionAttributeValues[":val"] = 1;

                    // Initialize clauses for DELETE
                    let deleteClause = "";

                    // Logic to handle UserUpvotes set
                    deleteClause = " DELETE UserUpvotes :deleteUpvoteUserId";
                    expressionAttributeValues[":deleteUpvoteUserId"] = dynamoDb.createSet([userId]);

                    // Combine the clauses
                    updateExpression += deleteClause;
                }
            } else {
                if (!hasDownvoted) {
                    // Initialize the SET clause for Downvotes
                    updateExpression = "SET Downvotes = Downvotes + :val";
                    expressionAttributeValues[":val"] = 1;
            
                    // Initialize clauses for ADD and DELETE
                    let addClause = "";
                    let deleteClause = "";
            
                    // Logic to handle UserDownvotes set
                    if (!currentItem.UserDownvotes) {
                        addClause = " UserDownvotes = :newDownvoteSet";
                        expressionAttributeValues[":newDownvoteSet"] = dynamoDb.createSet([userId]);
                    } else {
                        addClause = " ADD UserDownvotes :addDownvoteUserId";
                        expressionAttributeValues[":addDownvoteUserId"] = dynamoDb.createSet([userId]);
                    }
            
                    // Remove user from UserUpvotes set and decrement Upvotes
                    if (hasUpvoted) {
                        updateExpression += ", Upvotes = Upvotes - :val";
                        deleteClause = " DELETE UserUpvotes :deleteDownvoteUserId";
                        expressionAttributeValues[":deleteDownvoteUserId"] = dynamoDb.createSet([userId]);
                    }
            
                    // Combine the clauses
                    updateExpression = updateExpression + (addClause ? " " + addClause : "") + (deleteClause ? " " + deleteClause : "");
                }else if (hasDownvoted){
                    // Initialize the SET clause for Upvotes
                updateExpression = "SET Downvotes = Downvotes - :val";
                expressionAttributeValues[":val"] = 1;

                // Initialize clauses for DELETE
                let deleteClause = "";

                // Logic to handle UserUpvotes set
                deleteClause = " DELETE UserDownvotes :deleteDownvoteUserId";
                expressionAttributeValues[":deleteDownvoteUserId"] = dynamoDb.createSet([userId]);

                // Combine the clauses
                updateExpression += deleteClause;
            }
            }
    
            if (updateExpression !== "") {
                const updateParams = {
                    TableName: "AlphaGrimoires",
                    Key: key,
                    UpdateExpression: updateExpression,
                    ExpressionAttributeValues: expressionAttributeValues,
                    ReturnValues: "UPDATED_NEW"
                };
                await dynamoDb.update(updateParams).promise();
    
               
                // Update local state logic
                onVoteCompletion();

            }
        } catch (error) {
            console.error("Error updating vote count:", error);
        }
    };
    
    const handleCommentsClick = (prompt) => {
        setActivePrompt(prompt); // Set the active prompt when comments button is clicked
    };

    const handleCloseComments = () => {
        setActivePrompt(null); // Clear the active prompt when comments are closed
    };

    const handleCommentSubmit = async (newCommentText) => {
        if (!activePrompt || !newCommentText.trim()) {
            // Handle the case where there is no active prompt or the comment is empty
            return;
        }
        
        // Get the email of the currently authenticated user
        const user = await Auth.currentAuthenticatedUser();
        const userEmail = user.attributes.email;

        const { Title, Category, Comments } = activePrompt;

        // Create a comment object with the user's email and the comment text
        const newComment = {
            email: userEmail,
            comment: newCommentText
        };
        // Append the new comment to the existing comments
        const updatedComments = Comments ? [...Comments, newComment] : [newComment];
    
        const params = {
            TableName: "AlphaGrimoires", // Replace with your table name
            Key: {
                "Title": Title,
                "Category": Category
            },
            UpdateExpression: "set Comments = :comments",
            ExpressionAttributeValues: {
                ":comments": updatedComments
            },
            ReturnValues: "UPDATED_NEW"
        };
    
        try {
            await dynamoDb.update(params).promise();
    
            onVoteCompletion();
            const temp = activePrompt;
            setActivePrompt(null);

            // Optionally, refresh the local state
            // ...
    
            // Reopen the comments sidebar with the updated prompt
            const updatedActivePrompt = { ...activePrompt, Comments: updatedComments };
            setActivePrompt(updatedActivePrompt);
        } catch (error) {
            console.error("Error updating comments:", error);
            // Handle error appropriately
        }
    };

    
    
    const handleDeletion = async (title, category) => {
        const params = {
            TableName: "AlphaGrimoires", // Replace with your DynamoDB table name
            Key: {
                "Title": title,
                "Category": category
            }
        };
    
        try {
            await dynamoDb.delete(params).promise();
            console.log("Prompt deleted successfully");
            onVoteCompletion();
        } catch (error) {
            console.error("Error deleting prompt:", error);
            // Handle error appropriately
        }
    };

    const handleCommentsReset = async (title, category) => {
        // Confirm that title and category are valid
        if (!title || !category) {
            console.error("Invalid title or category for resetting comments.");
            return;
        }
    
        const params = {
            TableName: "AlphaGrimoires", // Replace with your table name
            Key: {
                "Title": title,
                "Category": category
            },
            UpdateExpression: "set Comments = :comments",
            ExpressionAttributeValues: {
                ":comments": [] // Resetting the comments to an empty array
            },
            ReturnValues: "UPDATED_NEW"
        };
    
        try {
            await dynamoDb.update(params).promise();
            onVoteCompletion();
    
        } catch (error) {
            console.error("Error resetting comments:", error);
            // Handle error appropriately
        }
    };
    

    const handleVotesReset = async (title, category) => {
        if (!title || !category) {
            console.error("Invalid title or category for resetting votes.");
            return;
        }
    
        const params = {
            TableName: "AlphaGrimoires", // Replace with your table name
            Key: {
                "Title": title,
                "Category": category
            },
            UpdateExpression: "set Upvotes = :upvotes, Downvotes = :downvotes, UserUpvotes = :userUpvotes, UserDownvotes = :userDownvotes",
            ExpressionAttributeValues: {
                ":upvotes": 0,
                ":downvotes": 0,
                ":userUpvotes": dynamoDb.createSet(["*"]),
                ":userDownvotes": dynamoDb.createSet(["*"])
            },
            ReturnValues: "UPDATED_NEW"
        };
    
        try {
            await dynamoDb.update(params).promise();
            onVoteCompletion();
    
        } catch (error) {
            console.error("Error resetting votes:", error);
            // Handle error appropriately
        }
    };

    return (
        <div className="search-results-container">
            {prompts.map((prompt, index) => (
            (isAdmin & isAdminView) ? 
                <EditablePromptResult
                    key={index}
                    title={prompt.Title}
                    category={prompt.Category}
                    otherCategories={prompt.OtherCategories || []}
                    body={prompt.Body}
                    upvotes={prompt.Upvotes}
                    downvotes={prompt.Downvotes}
                    userHasUpvoted={prompt.UserUpvotes?.values.includes(currentUserUsername)} // Adjusted for DynamoDB Set
                    userHasDownvoted={prompt.UserDownvotes?.values.includes(currentUserUsername)}
                    comments={prompt.Comments || []}
                    link={prompt.Link}
                    docLink={prompt.DocLink}
                    onCommentsClick={() => handleCommentsClick(prompt)}
                    onUpvote={() => handleVote(prompt.Title, prompt.Category, true)}
                    onDownvote={() => handleVote(prompt.Title, prompt.Category, false)}
                    onCopy={() => handleCopy(prompt.Body)}
                    onUpdatePrompt={handleUpdatePrompt}
                    onDelete={handleDeletion}
                    onCommentReset={() => handleCommentsReset(prompt.Title, prompt.Category)}
                    onVoteReset={() => handleVotesReset(prompt.Title, prompt.Category)}
                /> :
                <PromptResult 
                    key={index}
                    title={prompt.Title}
                    category={prompt.Category}
                    otherCategories={prompt.OtherCategories || []}
                    body={prompt.Body}
                    upvotes={prompt.Upvotes}
                    downvotes={prompt.Downvotes}
                    userHasUpvoted={prompt.UserUpvotes?.values.includes(currentUserUsername)} // Adjusted for DynamoDB Set
                    userHasDownvoted={prompt.UserDownvotes?.values.includes(currentUserUsername)}
                    comments={prompt.Comments || []}
                    link={prompt.Link}
                    docLink={prompt.DocLink}
                    onCommentsClick={() => handleCommentsClick(prompt)}
                    onUpvote={() => handleVote(prompt.Title, prompt.Category, true)}
                    onDownvote={() => handleVote(prompt.Title, prompt.Category, false)}
                    onCopy={() => handleCopy(prompt.Body)}
                    onDelete={handleDeletion}
                    isAdmin={isAdmin}
                />
            ))}
            {activePrompt && (
                <Comments 
                    comments={activePrompt.Comments} 
                    onClose={handleCloseComments}
                    onCommentSubmit={handleCommentSubmit}
                />
            )}
        </div>
    );
}

export default SearchResultsContainer;
