import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

//import './AppStyles.css';

const SearchBar = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Create a debounced version of onSearch
    const debouncedOnSearch = useCallback(
      debounce((query) => {
        onSearch(query);
      }, 100), // 100ms debounce time
      [] // Dependencies array, empty means the debounced function is created once
    );

    const handleSearchClick = () => {
      onSearch(searchTerm);
    };
    
    const handleKeyPress = (e) => {
        debouncedOnSearch(searchTerm);
    };
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '91.6%',
          padding: '2px 4px',
          boxSizing: 'border-box',
          marginBottom: 2, // you can adjust the margin as needed
          mx: 'auto',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search prompts..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{ marginRight: 1 }} // adds a margin to the right of the TextField
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearchClick}
          sx={{ padding: '10px' }} // adjust the padding to match the height of the TextField
          startIcon={<SearchIcon />} // adds a search icon to the button
        >
          Search
        </Button>
      </Box>
    );
}

export default SearchBar;
