//External Imports
import AWS from 'aws-sdk';
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

//MUI Components
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

//App Components
import MyAppBar from './MyAppBar';
import CategoryDropdown from './CategoryDropdown';
import NewPromptSubmission from './NewPromptSubmission';
import SearchBar from './SearchBar';
import SearchResultsContainer from './SearchResultsContainer';
import SortDropdown from './SortDropdown';


//Custom Imports
import './AppStyles.css';

function App() {
  const [categories, setCategories] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [lastSearchTerm, setLastSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [selectedSort, setSelectedSort] = useState('title');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdminView, setIsAdminView] = useState(false);

  const [showNewPromptForm, setShowNewPromptForm] = useState(false);  

  const theme = createTheme({
    palette: {
      mode: 'light', // Enable dark mode
      primary: {
        main: '#bb86fc', // A light shade for primary elements, adjust as needed
      },
      secondary: {
        main: '#41b8d4', // A contrasting color for secondary elements
      },
      error: {
        main: '#DF2935', // Color for error messages and icons
      },
      background: {
        default: '#ffffff', // Dark background color
        paper: '#f5f5f5',
      },
      text: {
        primary: '#000000',
        secondary: '#4f4f4f',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0)', // Light text for buttons
            backgroundColor: 'rgba(245, 245, 245)', // Slightly lighter background for buttons
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)', // More prominent background on hover
            },
            // Add more styling as needed for different button states
          },
        },
      },
    },
    typography: {
      fontFamily: '"Roboto"' , // Default font family
      h1: {
        fontFamily: '"Liberation Serif"',
        fontSize: '1.5rem', // Custom font size for h1
        fontWeight: 500, // Custom font weight for h1
        // Add other styles as needed
      },
      h2: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: '1.5rem',
        fontWeight: 500,
        // Add other styles as needed
      },
      // Customize other variants as needed
      body1: {
        fontFamily: '"Roboto"',
        fontSize: '1.0rem',
        // Add other styles as needed
      },
      body2: {
        fontFamily: '"Liberation Serif"',
        fontSize: '1.0rem',
        // Customize as needed
      },
      // You can add more text variants here
    },
    // ... other customizations
  });


  AWS.config.update({
    accessKeyId: 'AKIASVEIENDKNSTCMLNJ', 
    secretAccessKey: '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',
    region: 'us-east-1'
  });

  const dynamoDb = new AWS.DynamoDB.DocumentClient();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups && groups.includes("Admin")) {
            setIsAdmin(true);
        }
    }).catch(err => console.error(err));
}, []);

  useEffect(() => {
    searchPrompts('', 'All Categories', 'title');
  }, []);

  const fetchCategories = async () => {
    const params = {
        TableName: "AlphaGrimoires",
        ProjectionExpression: "Category, OtherCategories",
    };

    try {
        const data = await dynamoDb.scan(params).promise();
        let allCategories = [];

        // Loop through all items and add categories and otherCategories to allCategories array
        data.Items.forEach(item => {
            // Add the primary category
            if (item.Category) {
                allCategories.push(item.Category);
            }

            // Add all other categories
            if (item.OtherCategories && Array.isArray(item.OtherCategories)) {
                allCategories = allCategories.concat(item.OtherCategories);
            }
        });

        // Use a Set to get unique category values
        const categorySet = new Set(allCategories);
        setCategories([...categorySet]);
    } catch (error) {
        console.error("Error fetching categories:", error);
    }
};


  // Fetch categories when the component mounts
  useEffect(() => {
    fetchCategories();
  }, []);


  const searchPrompts = async (searchTerm, selectedCategory, sortOption) => {
    let params = {
        TableName: "AlphaGrimoires",
        FilterExpression: "contains(Title, :title)",
        ExpressionAttributeValues: {
            ":title": searchTerm,
        }
    };

    if (selectedCategory && selectedCategory !== "All Categories") {
      // Adjust the FilterExpression to include the primary Category and OtherCategories
      params.FilterExpression += " and (Category = :category or contains(OtherCategories, :category))";
      params.ExpressionAttributeValues[":category"] = selectedCategory;
  }

    try {
        const data = await dynamoDb.scan(params).promise();
        let sortedItems = data.Items;

        // Apply sorting based on sortOption
        switch (sortOption) {
            case 'title':
                // Sort alphabetically by title
                sortedItems.sort((a, b) => a.Title.localeCompare(b.Title));
                break;
            case 'upvotes':
                // Sort by upvotes in descending order
                sortedItems.sort((a, b) => b.Upvotes - a.Upvotes);
                break;
            case 'downvotes':
                // Sort by downvotes in descending order
                sortedItems.sort((a, b) => b.Downvotes - a.Downvotes);
                break;
            default:
                // Default case if needed
                break;
        }

        setPrompts(sortedItems);
    } catch (error) {
        console.error("Error searching prompts:", error);
    }
};


  const handleLogout = async () => {
    try {
      await Auth.signOut(); // Signs out the current authenticated user
    } catch (error) {
      console.error('Error signing out user: ', error);
    }
  };

  const handleSearch = (searchTerm) => {
    // Call the search function
    setLastSearchTerm(searchTerm)
    searchPrompts(searchTerm,selectedCategory, selectedSort);
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    searchPrompts(lastSearchTerm, category, selectedSort);
  };
  
  const handleNewPromptSubmit = async (newPrompt) => {
    // Close the New Prompt form
    setShowNewPromptForm(false);

    // Re-run the searchPrompts function to refresh the list
    await searchPrompts(lastSearchTerm, selectedCategory, selectedSort);
};

  const handleVoteCompletion = async () => {
    // Re-run the searchPrompts function to refresh the list
    await searchPrompts(lastSearchTerm, selectedCategory, selectedSort);
  };

  const handleSortChange = (newSort) => {
    setSelectedSort(newSort);
    searchPrompts(lastSearchTerm, selectedCategory, newSort);
};

  const handleCloseNewPrompt = () => {
    setShowNewPromptForm(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Normalize CSS */}
      <Container maxWidth="xl"> {/* Set max width for the container */}
        <Box sx={{ my: 4 }}> {/* Use Box for margins */}

            <MyAppBar
            isAdmin={isAdmin}
            isAdminView={isAdminView}
            setShowNewPromptForm={setShowNewPromptForm}
            setIsAdminView={setIsAdminView}
            onLogout={handleLogout}
          />

          {showNewPromptForm && (
            <NewPromptSubmission onSubmit={handleNewPromptSubmit} onClose={handleCloseNewPrompt} />
          )}
          <SearchBar /*searchTerm={searchTerm} setSearchTerm={setSearchTerm}*/ onSearch={handleSearch} />
          
          <Box sx={{ display: 'flex', my: 2, mx:'auto', width: '91%' }}>
            <Box sx={{ width: '135%', mx: 'auto' }}>
              <CategoryDropdown categories={categories} onCategoryChange={handleCategoryChange} selectedCategory={selectedCategory} />
            </Box>
            <Box sx={{ width: '100%', mx: 'auto' }}>
              <SortDropdown onSortChange={handleSortChange} selectedSort={selectedSort} />
            </Box>
          </Box>


          <SearchResultsContainer prompts={prompts} setPrompts={setPrompts} isAdmin={isAdmin} isAdminView={isAdminView} onVoteCompletion={handleVoteCompletion} />

        </Box>
      </Container>
      
    </ThemeProvider>
  );
}

export default App;
