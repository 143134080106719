import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function SortDropdown({ onSortChange, selectedSort }) {
  return (
    <FormControl color='secondary' sx={{ width: '100%', mx: 'auto' }}>
      <InputLabel id="sort-dropdown-label">Sort By</InputLabel>
      <Select
        labelId="sort-dropdown-label"
        value={selectedSort}
        onChange={(e) => onSortChange(e.target.value)}
        label="Sort By"
         // You can adjust the width as needed
      >
        <MenuItem value="title">Title</MenuItem>
        <MenuItem value="upvotes">Most Upvoted</MenuItem>
        <MenuItem value="downvotes">Most Downvoted</MenuItem>
        {/* Add more sort options as needed */}
      </Select>
    </FormControl>
  );
}


export default SortDropdown;
