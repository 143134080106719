import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AWS from 'aws-sdk';

const NewPromptSubmission = ({ onSubmit, onClose }) => {
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [body, setBody] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const theme = useTheme();
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Split the editableCategory by comma and trim spaces
        const categoriesArray = category.split(",").map(cat => cat.trim());
        
        // Extract the first category as the primary category
        const newCategory = categoriesArray[0];
                
        // The rest are the other categories
        const newOtherCategories = categoriesArray.slice(1);
            
        // Assuming DynamoDB setup is complete
        const dynamoDb = new AWS.DynamoDB.DocumentClient();
            // Scan operation to check for existing prompt with the same title
        const scanParams = {
            TableName: "AlphaGrimoires",
            FilterExpression: "Title = :title",
            ExpressionAttributeValues: {
                ":title": title
            }
        };

        try {
            const scanResult = await dynamoDb.scan(scanParams).promise();
            if (scanResult.Items && scanResult.Items.length > 0) {
                alert("A prompt with this title already exists.");
                return;
            }
            const newItem = {
                "Title": title,
                "Category": newCategory,
                "OtherCategories": newOtherCategories || [],
                "Body": body,
                "Upvotes": 0,
                "Downvotes": 0,
                "UserUpvotes": dynamoDb.createSet(['*']),
                "UserDownvotes": dynamoDb.createSet(['*']),
                "Comments": [],
                "Link": link || "",
                "DocLink": docLink || ""
            };
            await dynamoDb.put({ TableName: "AlphaGrimoires", Item: newItem }).promise();
            onSubmit(newItem); // Pass the new item back to the parent component
        } catch (error) {
            console.error("Error adding new prompt:", error);
        }
    };
    const textAreaStyle = {
      width: '100%',
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      fontSize: '1.0rem', // default font size for MUI TextField
      fontFamily: theme.typography.fontFamily,
      '&:focus': {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    };
    return (
      <Drawer anchor="right" open variant="persistent" sx={{'& .MuiDrawer-paper': {width: 350, backgroundColor: '#f5f5f5'}}}>
          <Box
            sx={{
              width: 350, // Adjust the width as needed
              padding: 2,
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Submit A New Prompt
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
              placeholder="Title"
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Category(s)"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              margin="normal"
              placeholder="Categories"
              helperText="Please enter the categories as a comma-separated list (e.g., 'QC, Workshops, Guide-Facing')"
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <TextareaAutosize
              minRows={3}
              style={textAreaStyle}
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Body"
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Link URL"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              margin="normal"
              helperText="Please enter the full URL (e.g., 'https://www.example.com')"
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Google Docs Link URL"
              value={docLink}
              onChange={(e) => setDocLink(e.target.value)}
              margin="normal"
              helperText="Please enter the full sharing Google Docs URL (e.g., 'https://docs.google.com/document/d/106dB0vFkBdWChEMoi2G1tSdr3bG9hyyKFKgSP92iu7Y/edit?usp=sharing')"
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary" type="submit" sx={{backgroundColor: '#41b8d4', color: '#ffffff' }}>
                Submit
              </Button>
              <Button variant="outlined" onClick={onClose}sx={{backgroundColor: '#DF2935', color: '#ffffff' }}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Drawer>
      );
};

export default NewPromptSubmission;
