import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import ArticleIcon from '@mui/icons-material/Article';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

//import './AppStyles.css';

const EditablePromptResult = ({ title, category, otherCategories, body, upvotes, downvotes, link, docLink, userHasUpvoted, userHasDownvoted, comments, onCommentsClick, onUpvote, onDownvote, onCopy, onUpdatePrompt, onDelete, onCommentReset, onVoteReset }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [editableTitle, setEditableTitle] = useState(title);
    const [editableBody, setEditableBody] = useState(body);
    const [editableLink, setEditableLink] = useState(link);
    const [editableDocLink, setEditableDocLink] = useState(docLink);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCommentsResetDialog, setOpenCommentsResetDialog] = useState(false);
    const [openVotesResetDialog, setOpenVotesResetDialog] = useState(false);
    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const combinedCategories = [category, ...(otherCategories || [])].join(", ");
    const [editableCategory, setEditableCategory] = useState(combinedCategories);

    const handleDeleteClick = () => {
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        setOpenDialog(false);
        onDelete(title, category); // Assuming onDelete is a prop function to handle deletion
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenCopyDialog = () => setOpenCopyDialog(true);
    const handleCloseCopyDialog = () => setOpenCopyDialog(false);


    const handleOpenCommentsResetDialog = () => setOpenCommentsResetDialog(true);
    const handleCloseCommentsResetDialog = () => setOpenCommentsResetDialog(false);

    const handleOpenVotesResetDialog = () => setOpenVotesResetDialog(true);
    const handleCloseVotesResetDialog = () => setOpenVotesResetDialog(false);

    const theme = useTheme();

    const textAreaStyle = {
        width: '100%',
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        fontSize: '0.875rem', // default font size for MUI TextField
        fontFamily: theme.typography.fontFamily,
        '&:focus': {
          outline: `2px solid ${theme.palette.primary.main}`,
        },
      };

    useEffect(() => {
        setEditableTitle(title);
        setEditableCategory(combinedCategories);
        setEditableBody(body);
        setEditableLink(link);
        setEditableDocLink(docLink)
    }, [title, category, otherCategories, body, link, docLink]);
    

    const handleSave = () => {
        // Split the editableCategory by comma and trim spaces
        const categoriesArray = editableCategory.split(",").map(cat => cat.trim());
        
        // Extract the first category as the primary category
        const newCategory = categoriesArray[0];
        
        // The rest are the other categories
        const newOtherCategories = categoriesArray.slice(1);
    
        // Call onUpdatePrompt with the separated categories
        onUpdatePrompt(title, category, {
            title: editableTitle,
            category: newCategory,
            otherCategories: newOtherCategories, // Update the structure as required
            body: editableBody,
            link: editableLink,
            docLink: editableDocLink
        });
    };
    return (
        <Card sx={{ width:'93.5%', marginBottom: 2 }}>
          <CardContent>
            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              value={editableTitle}
              onChange={(e) => setEditableTitle(e.target.value)}
              margin="normal"
              sx={{ fontFamily:'Roboto' }}
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Categories"
              value={editableCategory}
              onChange={(e) => setEditableCategory(e.target.value)}
              margin="normal"
              helperText="Please enter as a comma-seperated list (eg. 'Student-Facing, High standards, Workshops'))"
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <TextareaAutosize
              minRows={3}
              style={textAreaStyle}
              value={editableBody}
              onChange={(e) => setEditableBody(e.target.value)}
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Link URL"
              value={editableLink}
              onChange={(e) => setEditableLink(e.target.value)}
              margin="normal"
              helperText="Please enter the full URL (e.g., 'https://www.example.com')"
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Google Docs Link URL"
              value={editableDocLink}
              onChange={(e) => setEditableDocLink(e.target.value)}
              margin="normal"
              helperText="Please enter the full sharing Google Docs URL (e.g., 'https://docs.google.com/document/d/106dB0vFkBdWChEMoi2G1tSdr3bG9hyyKFKgSP92iu7Y/edit?usp=sharing')"
              InputLabelProps={{
                shrink: true,  // This ensures the label always shrinks
              }}
            />
          </CardContent>
          <CardActions disableSpacing>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Update
            </Button>
            <IconButton aria-label="upvote" color={userHasUpvoted ? "secondary" : "default"} onClick={() => onUpvote(title, category)}>
            {userHasUpvoted ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
              <span>{upvotes}</span>
            </IconButton>
            <IconButton aria-label="downvote" color={userHasDownvoted ? "error" : "default"} onClick={() => onDownvote(title, category)}>
            {userHasDownvoted ? <ThumbDownIcon /> : <ThumbDownOutlinedIcon />}
              <span>{downvotes}</span>
            </IconButton>
            <IconButton aria-label="copy" onClick={() => { onCopy(); handleOpenCopyDialog(); }}>
                <ContentCopyIcon />
            </IconButton>
            {link && (
                <Link href={link} target="_blank" rel="noopener noreferrer">
                    <IconButton aria-label="link">
                        <LinkIcon />
                    </IconButton>
                </Link>
            )}
            {docLink && (
                <Link href={docLink} target="_blank" rel="noopener noreferrer">
                    <IconButton aria-label="doclink">
                        <ArticleIcon />
                    </IconButton>
                </Link>
            )}
            <IconButton aria-label="delete" onClick={handleDeleteClick}>
                    <DeleteIcon />
            </IconButton>
            <Button size="small" onClick={(e) => {e.preventDefault(); onCommentsClick();}}>
              Comments ({comments.length})
            </Button>
            {/* Reset Votes Button */}
            <Button variant="contained" color="primary" sx={{ marginLeft: 2,  marginRight: 2 }} onClick={handleOpenVotesResetDialog}>
              Reset Votes
            </Button>
            {/* Reset Comments Button */}
            <Button variant="contained" color="primary" onClick={handleOpenCommentsResetDialog}>
              Reset Comments
            </Button>
          </CardActions>
          <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this prompt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Reset Comments Confirmation Dialog */}
            <Dialog
                open={openCommentsResetDialog}
                onClose={handleCloseCommentsResetDialog}
                aria-labelledby="reset-comments-dialog-title"
                aria-describedby="reset-comments-dialog-description"
            >
                <DialogTitle id="reset-comments-dialog-title">{"Confirm Reset Comments"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="reset-comments-dialog-description">
                        Are you sure you want to reset all comments for this prompt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCommentsResetDialog}>Cancel</Button>
                    <Button onClick={() => { onCommentReset(); handleCloseCommentsResetDialog(); }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Reset Votes Confirmation Dialog */}
            <Dialog
                open={openVotesResetDialog}
                onClose={handleCloseVotesResetDialog}
                aria-labelledby="reset-votes-dialog-title"
                aria-describedby="reset-votes-dialog-description"
            >
                <DialogTitle id="reset-votes-dialog-title">{"Confirm Reset Votes"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="reset-votes-dialog-description">
                        Are you sure you want to reset all votes for this prompt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseVotesResetDialog}>Cancel</Button>
                    <Button onClick={() => { onVoteReset(); handleCloseVotesResetDialog(); }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Copy Confirmation Dialog */}
            <Dialog
                open={openCopyDialog}
                onClose={handleCloseCopyDialog}
                aria-labelledby="copy-dialog-title"
                aria-describedby="copy-dialog-description"
            >
                <DialogTitle id="copy-dialog-title">{"Success!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="copy-dialog-description">
                        Prompt text has been copied to the clipboard.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCopyDialog} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
      );
}

export default EditablePromptResult;
