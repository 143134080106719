import React, { useEffect, useState } from 'react';
import { Authenticator} from '@aws-amplify/ui-react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Auth, Hub } from 'aws-amplify';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import App from './App';
import './AppStyles.css';
import Logo from './alpha-logo.svg'
function AppWrapper() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        Hub.listen('auth', (data) => {
            const { payload } = data;
            if (payload.event === 'signIn') {
                setIsAuthenticated(true);
            }
            if (payload.event === 'signOut') {
                setIsAuthenticated(false);
            }
        });

        Auth.currentAuthenticatedUser()
            .then(() => setIsAuthenticated(true))
            .catch(() => setIsAuthenticated(false));
    }, []);

    // Inline styles object to hide the non-federated sign-in options
    const customStyles = `
        .amplify-textfield {
            display: none;
        }
        .amplify-button--primary {
            display: none;
        }
        .amplify-divider {
            display: none;
        }
        .amplify-button--small {
            display: none;
        }
        .federated-sign-in-container {
            alignItems: 'center';
        }
        /* You might need to add more specific rules to target only the email field */
    `;

    return (
        <>
        {!isAuthenticated && (
          <Box sx={{ my: 4 }}> 
    <AppBar position="static" color="secondary"  sx={{ backgroundColor:'#41b8d4' , color: '#FFFFFF',width: '81%', height:'65px', margin: '0 auto' }}>
      <Toolbar>
        {/* Left-aligned Logo */}
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-start' }}>
          <img src={Logo} alt="Alpha's Logo" style={{ height: '40px' }} />
        </Box>
      </Toolbar>
    </AppBar>
          </Box>
        )}
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            backgroundColor: '#ffffff',
          }}
        >

          <style>{customStyles}</style>
          {/* Title */}
          {!isAuthenticated && (
            <>
              <Typography variant="h2" component="h1" gutterBottom sx={{ color: '#000000', marginBottom: 0 }}>
                Alpha's Grimoires
              </Typography>
              <Typography variant="h5" sx={{ color: '#808080', marginBottom: 0}}>
                Alpha School's AI Prompt Repository
              </Typography>
            </>
           )}
          <Authenticator socialProviders={['google']} hideSignUp={true} >
            {({ signOut, user }) => {
              return user ? (
                <App user={user} signOut={signOut} />
              ) : (
                <div>Loading...</div> // You could replace this with a proper loading indicator
              );
            }}
          </Authenticator>
        </Box>
       
        </>
      );
      
}



export default AppWrapper;
