import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Logo from './alpha-logo.svg'
function MyAppBar({ isAdmin, isAdminView, setShowNewPromptForm, setIsAdminView, onLogout }) {
  return (
    <AppBar position="static" color="secondary" sx={{ color: '#FFFFFF', width: '91%', margin: '0 auto' }}>
      <Toolbar>
        {/* Left-aligned Logo */}
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-start' }}>
          <img src={Logo} alt="Alpha's Logo" style={{ height: '40px' }} />
        </Box>

        {/* Centered Text */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
          {isAdminView ? "Admin View" : "Alpha's Grimoires"}
        </Typography>

        {/* Right-aligned Buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isAdmin && (
            <Button variant="contained" sx={{ marginRight: 2, color: '#ffffff',  backgroundColor: '#4A2140', '&:hover': {backgroundColor: '#521945' }  }} onClick={() => setIsAdminView(prev => !prev)} >
              {isAdminView ? "Switch to User View" : "Switch to Admin View"}
            </Button>
          )}
          {isAdmin && (
            <Button variant="contained" sx={{ color: '#ffffff', backgroundColor: '#131F76', '&:hover': {backgroundColor: '#000F89' }  }} onClick={() => setShowNewPromptForm(true)}>
              Add new Prompt
            </Button>
          )}
          <Button sx={{ marginLeft: 2, color: '#ffffff', backgroundColor: '#D23842', '&:hover': {backgroundColor: '#DF2935' } }}  onClick={onLogout}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default MyAppBar;
