import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import ArticleIcon from '@mui/icons-material/Article';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

//import './AppStyles.css';
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const PromptResult = ({ title, category, otherCategories, body, upvotes, downvotes, link, docLink, userHasUpvoted, userHasDownvoted, comments, isAdmin, onCommentsClick, onUpvote, onDownvote, onCopy, onDelete }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const combinedCategories = [category, ...(otherCategories || [])].join(", ");

    const handleDeleteClick = () => {
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        setOpenDialog(false);
        onDelete(title, category); // Assuming onDelete is a prop function to handle deletion
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleOpenCopyDialog = () => setOpenCopyDialog(true);
    const handleCloseCopyDialog = () => setOpenCopyDialog(false);  

      return (
        <Card sx={{ width:'93.5%', marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h1" component="div">
              {title}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Category(s): {combinedCategories}
            </Typography>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Typography variant="body1" paragraph>{body}</Typography>
            </Collapse>
            {!isExpanded && (
              <Typography variant="body1" paragraph>{`${body.substring(0, 175)}...`}</Typography>
            )}
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="upvote" color={userHasUpvoted ? "secondary" : "default"} onClick={() => onUpvote(title, category)}>
            {userHasUpvoted ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
              <Box component="span" sx={{ marginLeft: 1 }}>{upvotes}</Box>
            </IconButton>
            <IconButton aria-label="downvote" color={userHasDownvoted ? "error" : "default"} onClick={() => onDownvote(title, category)}>
            {userHasDownvoted ? <ThumbDownIcon /> : <ThumbDownOutlinedIcon />}
              <Box component="span" sx={{ marginLeft: 1 }}>{downvotes}</Box>
            </IconButton>
            <ExpandMore
              expand={isExpanded}
              onClick={handleExpandClick}
              aria-expanded={isExpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <IconButton aria-label="copy" onClick={() => { onCopy(); handleOpenCopyDialog(); }}>
                <ContentCopyIcon />
            </IconButton>
            {link && (
                <Link href={link} target="_blank" rel="noopener noreferrer">
                    <IconButton aria-label="link">
                        <LinkIcon />
                    </IconButton>
                </Link>
            )}
            {docLink && (
                <Link href={docLink} target="_blank" rel="noopener noreferrer">
                    <IconButton aria-label="docLink">
                        <ArticleIcon />
                    </IconButton>
                </Link>
            )}
            {isAdmin && (
            <IconButton aria-label="delete" onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          )}
            <Button size="small" onClick={(e) => {e.preventDefault(); onCommentsClick();}}>
              Comments ({comments.length})
            </Button>

          </CardActions>
          <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this prompt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openCopyDialog}
                onClose={handleCloseCopyDialog}
                aria-labelledby="copy-dialog-title"
                aria-describedby="copy-dialog-description"
            >
                <DialogTitle id="copy-dialog-title">{"Success!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="copy-dialog-description">
                        Prompt text has been copied to the clipboard.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCopyDialog} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
      );
}

export default PromptResult;
